<template>
<div>
<app-header />
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">

      <!-- Login v1 -->
      <b-card class="mb-0">
        <!-- <b-link class="brand-logo">
          <vuexy-logo />

          <h2 class="brand-text text-primary" style="margin-top: 3px;">
            Hustleforge
          </h2>
        </b-link> -->

        <b-card-title class="mb-1">
          Welcome to Hustleforge...
        </b-card-title>
        <b-card-text class="mb-2">
          Sign in and start making connections today!
        </b-card-text>

        <!-- form -->
        <validation-observer
          ref="loginForm"
        >
          <b-form
            class="auth-login-form mt-2"
            @submit.prevent="login"
          >
            <!-- email -->
            <b-form-group
              label-for="email"
              label="Email"
            >
              <validation-provider
                #default="{ errors }"
                name="email"
                rules="required|email"
              >
                <b-form-input
                  id="email"
                  v-model="userEmail"
                  name="login-email"
                  :state="errors.length > 0 ? false:null"
                  placeholder="john@example.com"
                  autofocus
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- password -->
            <b-form-group>
              <div class="d-flex justify-content-between">
                <label for="password">Password</label>
                <b-link :to="{name:'auth-forgot-password'}">
                  <small>Forgot Password?</small>
                </b-link>
              </div>
              <validation-provider
                #default="{ errors }"
                name="Password"
                rules="required"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid':null"
                >
                  <b-form-input
                    id="password"
                    v-model="password"
                    :type="passwordFieldType"
                    class="form-control-merge"
                    :state="errors.length > 0 ? false:null"
                    name="login-password"
                    placeholder="Password"
                  />

                  <b-input-group-append is-text>
                    <feather-icon
                      class="cursor-pointer"
                      :icon="passwordToggleIcon"
                      @click="togglePasswordVisibility"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- checkbox -->
            <!-- TODO hook up <b-form-group>
              <b-form-checkbox
                id="remember-me"
                v-model="status"
                name="checkbox-1"
              >
                Remember Me
              </b-form-checkbox>
            </b-form-group> -->

            <!-- submit button -->
            <b-button
              variant="primary"
              type="submit"
              block
            >
              Sign in
            </b-button>
            <!-- <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="outline-primary"
                v-on:click="loginLinkedin"
                block
              >
              <span class="align-middle">Login in with Linked</span>
              <feather-icon
                icon="LinkedinIcon"
                class="ml-60"
              />
            </b-button> -->

                          <!-- <b-button
                  style="background-color:transparent !important; border-color:transparent !important; padding:0px;" 
                >
                <b-img
                  :src="require('@/assets/images/icons/linkedin.png')"
                  alt="linkedinLogin"
                  v-on:click="loginLinkedin"
                />
              </b-button> -->
          </b-form>
        </validation-observer>

        <b-card-text class="text-center mt-2">
          <span>New to Hustleforge? </span>
          <b-link :to="{name:'auth-register'}">
            <span>Create an account</span>
          </b-link>
        </b-card-text>
      </b-card>
      <!-- /Login v1 -->
    </div>
  </div>
  <app-footer />
</div>

</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import {
  BButton, BForm, BFormInput, BFormGroup, BCard, BImg, BIcon, BLink, BCardTitle, BCardText, BInputGroup, BInputGroupAppend, BFormCheckbox,
} from 'bootstrap-vue'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import store from '@/store/index'
import { getHomeRouteForLoggedInUser } from '@/auth/utils'
import { getAuth, signInWithEmailAndPassword, sendEmailVerification} from "firebase/auth";
import { getFirestore, doc, getDoc, updateDoc, arrayUnion } from 'firebase/firestore';
import { getToken, isSupported} from "firebase/messaging"
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import AppFooter from '../landing/AppFooter.vue'
import AppHeader from '../landing/AppHeader.vue'
import Ripple from 'vue-ripple-directive'

const auth = getAuth()
const db = getFirestore()

export default {
  components: {
    // BSV
    BButton,
    BForm,
    BImg,
    BIcon,
    BFormInput,
    BFormGroup,
    BCard,
    BCardTitle,
    BLink,
    VuexyLogo,
    BCardText,
    BInputGroup,
    BInputGroupAppend,
    BFormCheckbox,
    ValidationProvider,
    ValidationObserver,
    AppFooter,
    AppHeader
  },
  directives: {
    Ripple,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      userEmail: '',
      password: '',
      status: '',
      // validation rules
      required,
      email,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  methods: {
    loginLinkedin(){
      window.open('/linkedin', 'firebaseAuth', 'height=415,width=500');
    },
    notifcationSupported(){
      return 'Notification' in window &&
      'serviceWorker' in navigator &&
      'PushManager' in window
    },
    async login() {

      // Validate form for to make sure data is correct
      const validateForm = await this.$refs.loginForm.validate()
      if (!validateForm) return

      // Login the new user 
      signInWithEmailAndPassword(auth, this.userEmail, this.password).then(async (userCredential) => {
        const user = userCredential.user;

        // Did we have a verified email
        if(!user.emailVerified){

           // Send email verification again
          sendEmailVerification(user)
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
              props: {
                title: `Verify your email`,
                icon: 'mail',
                variant: 'info',
                text: `Please verify your email before logging into you account`,
            },timeout: 5000
          })
          return
        }
        
        // Does the user have a profile already
        const profileRes = await getDoc(doc(db, "profiles", user.displayName))
        
        // Make sure we have a profile
        if (!profileRes.exists()){
            this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: `Login Failed`,
              icon: 'user-x',
              variant: 'danger',
              text: 'Could not find profile for you account. Please connact support@hustleforge.com',
            },timeout: 5000
          })
          return
        }

        // Build userData and store locally
        let profile = profileRes.data()
        profile.email = user.email // TODO remove this
        profile.profileComplete = profile.profileComplete
        profile.role = "admin" // Hardcode this for now
        profile.ability = []
        profile.ability.push({action: "manage", subject: "all"}) // Hardcode this for now

        localStorage.setItem('userData', JSON.stringify(profile))
        this.$ability.update(profile.ability) // TODO Change this later

        // ASk for a push token
        if (isSupported() && this.notifcationSupported()) {
          const permission = await Notification.requestPermission()
          if (permission === 'granted'){
            await getToken(this.$messaging, { vapidKey: 'BJluftTUTm76uZqpL8BhlG0I_d25Hos_qxZhciubU-3MAWbcjrbnd-pRgf3WFB_NQIO7B5YLbgmE1SxfNUYHeck' }).then((currentToken) => {
              if (currentToken) {
                  profile.pushToken = currentToken
                  updateDoc(doc(db, "profiles", user.displayName ), { pushTokens: arrayUnion(currentToken)})
              } else {
                console.log('No registration token available. Request permission to generate one.')
              }
            })
          }
        }

        // Send to dashboard
        const route = (profile.profileComplete)?getHomeRouteForLoggedInUser(profile.role):'/app/onboarding'
        this.$router.replace(route).then(() => {
          
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: `Welcome ${profile.fullName}`,
              icon: 'CoffeeIcon',
              variant: 'success',
            },
          })
        })
      }).catch((error) => {
        console.log(error)

        let errorMessage = error;
        if(error.code) errorMessage = error.code.replace("auth/","").replaceAll("-", " ");

        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: `Login Failed`,
            icon: 'user-x',
            variant: 'danger',
            text: errorMessage,
          },
        })
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
